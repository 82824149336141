import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const SimpleReactLightbox = makeShortcode("SimpleReactLightbox");
const LightBox = makeShortcode("LightBox");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const AndManyMore = makeShortcode("AndManyMore");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <SimpleReactLightbox mdxType="SimpleReactLightbox">
  <LightBox mdxType="LightBox">
    <Container mdxType="Container">
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fog" modelName="Daria Zakharova" place="Moscow" filename="fog.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Fog" modelName="Daria Zakharova" place="Moscow" filename="rust.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Papuasovo" modelName="Tania Bond" place="Rostov-on-Don" filename="tania-tribe.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Titan" modelName="Dmitriy" place="Moscow" filename="titan-photography-by-andrey-bondarenko.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Headache" modelName="Andrey Bondarenko" place="Moscow" filename="headache-photography-by-andrey-bond.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Headache" modelName="Andrey Bondarenko" place="Moscow" filename="headache-photography-by-andrey-bond-3.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Headache" modelName="Andrey Bondarenko" place="Moscow" filename="headache-photography-by-andrey-bond-2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Headache" modelName="Andrey Bondarenko" place="Moscow" filename="headache-photography-by-andrey-bond-4.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Visa Canada" modelName="Natalia Moskalenko" place="Puschino" filename="Natalia-VISA-Canada-1080.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Visa Portugal" modelName="Renat" place="Puschino" filename="Renat-VISA-Portugal1024.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Visa USA" modelName="Simon Dmitriev" place="Moscow" filename="Simon-VISA-USA.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
    </Container>
  </LightBox>
    </SimpleReactLightbox>
    <AndManyMore mdxType="AndManyMore" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      